import {signal} from '@preact/signals';
import {LocalizedStrings} from './types';

/**
 * A signal for the localized strings store.
 */
const localizedStrings = signal<Partial<LocalizedStrings>>({});

/**
 * Get the localized strings.
 * This makes the global localized strings store available to all components.
 * All strings are not necessarily available at all times,
 * so if a string is not found, you may need to load it using the `LocalizedStringLoader` component.
 * Be sure to provide a default value in case the string is not found.
 *
 * @returns an object of localized strings.
 * @example
 * ```tsx
 * const strings = getLocalizedStrings();
 * const defaultTitle = 'Default Title';
 * return <h1>{strings.pageTitle ?? defaultTitle}</h1>;
 * ```
 */
export function getLocalizedStrings() {
  return localizedStrings.value;
}

/**
 * Set the localized strings.
 * This is a global setter for the localized strings store.
 * It takes an object with key-value pairs of localized strings.
 * To set strings from liquid, use the `LocalizedStringLoader` component.
 *
 * @param strings - the localized strings to set.
 * @example
 * ```tsx
 * setLocalizedStrings({pageTitle: 'New Page Title', anotherString: 'Another String'});
 * ```
 */
export function setLocalizedStrings(strings: Partial<LocalizedStrings>) {
  localizedStrings.value = Object.assign(localizedStrings.value, strings);
}
